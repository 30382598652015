
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MainLayout from '../components/MainLayout';

import SubpageHeader from '../components/SubpageHeader';
import PrivacyPolicy from '../components/PrivacyPolicy';

import createMetaData from '../utils/createMetaData';

const PagePrivacyPolicy = () => {
	const { pagePrivacyPolicyJson: json } = useStaticQuery(graphql`
		query {
			pagePrivacyPolicyJson {
				meta_description
				meta_keywords
				meta_title
				permalink
				title
			}
		}
	`);

	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<PrivacyPolicy />
		</MainLayout>
	);
};

export default PagePrivacyPolicy;
